<template>
  <div class="header-auth">
    <div class="header-auth__close" @click="$emit('closeAuth')">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.82001 4.25104L4.25106 2.81999L7.0739 5.64282L9.8939 2.82282L11.3193 4.24821L8.49929 7.06822L11.3221 9.89105L9.89108 11.3221L7.06824 8.49927L4.24823 11.3193L2.82284 9.89388L5.64285 7.07387L2.82001 4.25104Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div class="header-auth__title">Вход</div>
    <div class="header-auth__items">
      <div
        class="header-auth__item"
        @click="tab = 1"
        :class="{ 'header-auth__item_active': tab === 1 }"
      >
        Телефон
      </div>
      <div
        class="header-auth__item"
        @click="tab = 2"
        :class="{ 'header-auth__item_active': tab === 2 }"
      >
        Email
      </div>
    </div>
    <div class="header-auth__tab" v-if="tab === 1">
      <div v-if="!codeSend">
        <TextField
          :type="'tel'"
          :maskString="'+7 (###) ###-##-##'"
          :placeholder="'+7(---) --- -- --'"
          v-model="phoneNumber"
          @keyup.native.enter="validate(getSmsCode)"
          :rules="[
            Rules.isRequired(phoneNumber, 'Некорректный номер'),
            Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
          ]"
        ></TextField>
        <div
          class="header-auth__get-sms button_pink button"
          @click="validate(getSmsCode)"
        >
          Получить код
        </div>
      </div>
      <div v-else-if="!codeHelp">
        <div class="header-auth__description">
          Отправили смс с кодом на номер +7 {{ phoneNumber }}.
          <br />
          <span class="header-auth__description_pink" @click="codeSend = false"
            >Изменить номер?</span
          >
        </div>
        <TextField
          :type="'tel'"
          v-model="smsCode"
          :maskString="'####'"
          :rules="[
            Rules.isRequired(smsCode, 'Неправильный код'),
            authSmsConfirmed !== false || 'Неправильный код',
          ]"
          :successState="authSmsConfirmed"
          class="mb-4"
        ></TextField>
        <GetCode :refresh="authPhoneResend" />
        <div class="header-auth__code-help" @click="codeHelp = true">
          Не пришел код
        </div>
      </div>
      <div v-else>
        <div class="header-auth__help-caption">Что делать</div>
        <ol class="header-auth__help-list">
          <li class="header-auth__help-list-item">
            Возможно, вы ввели не тот номер или ошиблись в цифре. Проверьте всё
            ещё раз.
          </li>
          <li class="header-auth__help-list-item">
            Проблема может быть со стороны вашего оператора сотовой связи.
            Обратитесь в службу поддержки.
          </li>
        </ol>
        <div
          class="header-auth__back button_small button button_empty-pink"
          @click="codeHelp = false"
        >
          <span class="mr-2">
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1L1 6L6 11"
                stroke="currentColor"
                stroke-linecap="square"
              />
            </svg>
          </span>
          Назад
        </div>
      </div>
    </div>
    <div class="header-auth__tab" v-if="tab === 2">
      <TextField
        :type="'email'"
        v-model="email"
        :placeholder="'E-mail'"
        @keyup.native.enter="validate(auth, authData)"
        :rules="[
          Rules.isRequired(email, 'Нужно заполнить Email адрес'),
          Rules.isCorrectEmail(email, 'Некорректный email'),
        ]"
        class="mb-3"
      />
      <TextField
        :type="showPass ? 'text' : 'password'"
        v-model="password"
        @keyup.native.enter="validate(auth, authData)"
        :placeholder="'Пароль'"
        :rules="[
          Rules.isRequired(password, 'Введите пароль'),
          !inputError || inputError,
        ]"
        class="pass-block"
      />
      <router-link
        :to="{ name: 'RestorePassword' }"
        class="header-auth__forgot-pass"
        >Забыли пароль?</router-link
      >
      <div
        class="header-auth__login mt-4 button_pink button"
        @click="validate(auth, authData)"
      >
        Войти
      </div>
    </div>
    <div class="header-auth__sso mt-6 pt-6">
      <a
        href="https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token"
        class="button_gray button"
      >
        Вход сотрудникам AZ
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TextField from "@/components/form-elements/TextField.vue";
import GetCode from "@/components/form-elements/getCode.vue";
import { bus } from "@/main";
export default {
  components: { TextField, GetCode },
  name: "Auth",
  data: () => ({
    tab: 1,
    phoneNumber: "",
    codeSend: false,
    smsCode: "",
    codeHelp: false,
    email: "",
    password: "",
    showPass: "",
  }),
  computed: {
    ...mapGetters(["Rules", "authSmsConfirmed", "inputError"]),
    authData() {
      return {
        username: this.email.toLowerCase(),
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions([
      "auth",
      "authPhone",
      "authPhoneResend",
      "authPhoneCode",
      "clearInputError",
    ]),
    ...mapMutations(['updateAuthSmsConfirmed']),
    async getSmsCode() {
      if (await this.authPhone(this.phoneNumber)) {
        this.codeSend = true;
      }
    },
    async validate(callback, arg) {
      const vm = this;
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            bus.data.result = [];
            this.$emit("closeAuth");
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "auth success", {
                "auth success": {
                 ...this.$root.ymFileds
                },
              });
            }
          }
        }
      }
      bus.data.result = [];
    },
  },
  watch: {
    async smsCode() {
      const vm = this;
      if (this.smsCode.length >= 4) {
        let res = await this.authPhoneCode(this.smsCode);
        this.validate();
        if (res) {
          this.$emit("closeAuth");
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "auth success", {
              "auth success": {
                 ...this.$root.ymFields
              },
            });
          }
        }
      } else {
        this.updateAuthSmsConfirmed(null);
      }
    },
    async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async email() {
      if (this.inputError) {
        this.clearInputError();
      }
    },
    async password() {
      if (this.inputError) {
        this.clearInputError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-auth {
  z-index: 1000;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 32px;
  background: #ffffff;
  border-bottom: 4px solid #c4d600;

  &__sso {
    border-top: 1px solid #ebefee;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: #b2b4b4;
    transition: 0.3s;

    &:hover {
      color: #830051;
    }
  }

  &__tab {
    width: 216px;
  }

  &__get-sms {
    width: 100%;
    height: 40px;
    margin-top: 16px;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #424753;
  }

  &__items {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
  }

  &__item {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #b2b4b4;
    padding-bottom: 8px;
    cursor: pointer;

    &_active {
      color: #3c4242;
      border-bottom: 2px solid #d0006f;
    }
  }

  &__description {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }

  &__code-help {
    margin-top: 6px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;
  }

  &__help-caption {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #424753;
  }

  &__help-list {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    padding-left: 15px;
    list-style-type: number;
  }

  &__help-list-item {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__back {
    margin-top: 16px;
    display: inline-flex;
    font-size: 14px;
    line-height: 21px;
  }

  &__forgot-pass {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #b2b4b4;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #d0006f;
    }
  }
  .get-code .get-code__timer {
    text-align: left;
  }
}
</style>
<style lang="scss">
.get-code__refresh-code {
  width: 100%;
}

.header .textfield__input {
  font-size: 14px;
}
</style>