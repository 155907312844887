var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-auth"},[_c('div',{staticClass:"header-auth__close",on:{"click":function($event){return _vm.$emit('closeAuth')}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.82001 4.25104L4.25106 2.81999L7.0739 5.64282L9.8939 2.82282L11.3193 4.24821L8.49929 7.06822L11.3221 9.89105L9.89108 11.3221L7.06824 8.49927L4.24823 11.3193L2.82284 9.89388L5.64285 7.07387L2.82001 4.25104Z","fill":"currentColor"}})])]),_c('div',{staticClass:"header-auth__title"},[_vm._v("Вход")]),_c('div',{staticClass:"header-auth__items"},[_c('div',{staticClass:"header-auth__item",class:{ 'header-auth__item_active': _vm.tab === 1 },on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" Телефон ")]),_c('div',{staticClass:"header-auth__item",class:{ 'header-auth__item_active': _vm.tab === 2 },on:{"click":function($event){_vm.tab = 2}}},[_vm._v(" Email ")])]),(_vm.tab === 1)?_c('div',{staticClass:"header-auth__tab"},[(!_vm.codeSend)?_c('div',[_c('TextField',{attrs:{"type":'tel',"maskString":'+7 (###) ###-##-##',"placeholder":'+7(---) --- -- --',"rules":[
          _vm.Rules.isRequired(_vm.phoneNumber, 'Некорректный номер'),
          _vm.Rules.isCorrectPhone(_vm.phoneNumber, 'Некорректный номер') ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validate(_vm.getSmsCode)}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('div',{staticClass:"header-auth__get-sms button_pink button",on:{"click":function($event){return _vm.validate(_vm.getSmsCode)}}},[_vm._v(" Получить код ")])],1):(!_vm.codeHelp)?_c('div',[_c('div',{staticClass:"header-auth__description"},[_vm._v(" Отправили смс с кодом на номер +7 "+_vm._s(_vm.phoneNumber)+". "),_c('br'),_c('span',{staticClass:"header-auth__description_pink",on:{"click":function($event){_vm.codeSend = false}}},[_vm._v("Изменить номер?")])]),_c('TextField',{staticClass:"mb-4",attrs:{"type":'tel',"maskString":'####',"rules":[
          _vm.Rules.isRequired(_vm.smsCode, 'Неправильный код'),
          _vm.authSmsConfirmed !== false || 'Неправильный код' ],"successState":_vm.authSmsConfirmed},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}}),_c('GetCode',{attrs:{"refresh":_vm.authPhoneResend}}),_c('div',{staticClass:"header-auth__code-help",on:{"click":function($event){_vm.codeHelp = true}}},[_vm._v(" Не пришел код ")])],1):_c('div',[_c('div',{staticClass:"header-auth__help-caption"},[_vm._v("Что делать")]),_vm._m(0),_c('div',{staticClass:"header-auth__back button_small button button_empty-pink",on:{"click":function($event){_vm.codeHelp = false}}},[_c('span',{staticClass:"mr-2"},[_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 1L1 6L6 11","stroke":"currentColor","stroke-linecap":"square"}})])]),_vm._v(" Назад ")])])]):_vm._e(),(_vm.tab === 2)?_c('div',{staticClass:"header-auth__tab"},[_c('TextField',{staticClass:"mb-3",attrs:{"type":'email',"placeholder":'E-mail',"rules":[
        _vm.Rules.isRequired(_vm.email, 'Нужно заполнить Email адрес'),
        _vm.Rules.isCorrectEmail(_vm.email, 'Некорректный email') ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validate(_vm.auth, _vm.authData)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('TextField',{staticClass:"pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"placeholder":'Пароль',"rules":[
        _vm.Rules.isRequired(_vm.password, 'Введите пароль'),
        !_vm.inputError || _vm.inputError ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validate(_vm.auth, _vm.authData)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('router-link',{staticClass:"header-auth__forgot-pass",attrs:{"to":{ name: 'RestorePassword' }}},[_vm._v("Забыли пароль?")]),_c('div',{staticClass:"header-auth__login mt-4 button_pink button",on:{"click":function($event){return _vm.validate(_vm.auth, _vm.authData)}}},[_vm._v(" Войти ")])],1):_vm._e(),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"header-auth__help-list"},[_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Возможно, вы ввели не тот номер или ошиблись в цифре. Проверьте всё ещё раз. ")]),_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Проблема может быть со стороны вашего оператора сотовой связи. Обратитесь в службу поддержки. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-auth__sso mt-6 pt-6"},[_c('a',{staticClass:"button_gray button",attrs:{"href":"https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token"}},[_vm._v(" Вход сотрудникам AZ ")])])}]

export { render, staticRenderFns }